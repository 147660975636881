// accessibility helper functions -- ported from Princeton-CDH/ppa-django
@mixin sr-only { // hide for all but screen readers
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
}

.sr-only {
    @include sr-only();
}

.sr-only-focusable { // ensure only screen readers can focus
    &:active,
    &:focus {
      position: static;
      width: auto;
      height: auto;
      overflow: visible;
      clip: auto;
      white-space: normal;
  }
}