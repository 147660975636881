div.main,
article.main {
    @include flex-container;
    padding: 1.5rem 0;

    @media (min-width: $breakpoint-s) {
        padding: 2rem 0;
    }

    @media (min-width: $breakpoint-l) {
        padding: 2.5rem 0;
    }

    .attribution {
        margin-bottom: 2rem;

        .authors,
        .editors,
        .pubdate,
        .update {
            font-size: 0.8rem;
            display: block;
        }
    }

    ol,
    ul {
        margin: 2rem 0;

        li {
            margin-bottom: 1rem;
        }

        // pad distance between number/bullet and text
        li::before {
            content: "\200B";
            display: inline-block;
            width: 1rem;
        }
    }

    ul {
        list-style: square inside;
    }

    ol {
        list-style: decimal inside;
    }

    .linkable_section {

        h2 {
            margin-bottom: 1rem;
        }
    }

    .footnotes {
        border-top: 1px solid $light-grey;
        padding-top: 1rem;
        margin-top: 2rem;

        ol {
            padding: 0;
        }

        li {
            margin: 0;
        }

        li::before {
            display: none;
        }

        .rich-text {
            font-family: $sans;
            font-size: 0.8rem;
        }
    }
}

/* preliminary style to highlight an anchor link target (esp. for info links) */
:target {
    border-left: 3px solid $green;
    margin-left: -1rem;
    padding-left: 1rem;
}

body {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: $white;
    overflow-x: hidden; // prevent horizontal scrollbar
}
.breadcrumbs {
    @include padding-container;
    background: $white;
    padding-top: 1rem;
    padding-bottom: 1rem;

    ol {
        overflow: hidden;
    }

    li {
        float: left;
        position: relative;

        &.home {
            // Home crumb logo
            &::before {
                content: url('/static/img/logo/SCo_logo_graphic.svg');
                position: absolute;
                width: 1rem;
                height: 1.5rem;
                z-index: 1;
                left: .1rem;
                pointer-events: none;
            }

            &:nth-last-child(4), &:nth-last-child(5) {
                a {
                    width: 1px;
                    color: white;
                }
            }


            a {
                border-left-width: 2px;
                // don't show 'Home' text unless on larger screens
                width: 1px;
                color: white;

                // don't show 'Home' whene there are 4 breadcrumbs
                li:nth-last-child(4) & {
                    width: 1px;
                    color: white;
                }

                @media (min-width: $breakpoint-s) {
                    width: auto;
                    color: inherit;
                }
            }
        }

        // hide crumbs beyond level 4
        &:nth-child(n + 5) {
            a, span {
                display: none;
            }
        }
        &:nth-last-child(2) a::before {
            border-left-color: $green;
        }
        &:nth-child(4) a::before {
            border-left-color: $white-two;
        }
    }

    a,
    span {
        height: 1.5rem;
        line-height: 1.5rem;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: baseline;
        background: white;
        font-size: 0.7rem;
        padding-left: 1rem;
        border-color: $white-two;
        border-style: solid;
        border-bottom-width: 2px;
        border-top-width: 2px;
        box-sizing: border-box;
        text-decoration: none;

        &::before,
        &::after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            position: absolute;
            left: 100%;
            border-style: solid;
            border-color: transparent;
        }

        &::before {
            top: -2px;
            z-index: 1;
            transition: all 0.2s;
            border-width: 0.75rem 0 0.75rem 0.75rem;
            border-left-color: $white-two;
        }

        &::after {
            top: 0;
            z-index: 2;
            border-width: calc(0.75rem - 2px) 0 calc(0.75rem - 2px) calc(0.75rem - 2px);
            border-left-color: white;
        }
    }

    // remove default hover styles for links
    a:hover,
    a:focus {
        border-color: $white-two;
    }

    // focused links get bold text for visibility for kbd users
    a:focus {
        font-weight: bold;
    }

    // last item is the active one, green border
    span {
        border-color: $green;
        font-weight: bold;
            display: flex;

        &::before {
            border-left-color: $green;
        }
    }
}
a.read-more {
    width: 6rem;
    height: 2rem;
    font-size: 0.8rem;
    border: 2px solid $black;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    transition: all 0.2s ease;
    text-decoration: none;
    color: black;

    &:hover, &:focus {
        border: 2px solid $black;
        background: $black;
        color: white;
    }
}

button {
    padding: 7px 14px;
    background-color: white;
    border: none;
    box-shadow: 0 2px 4px 0 rgba(106, 106, 106, 0.5);
    font-size: 0.7rem;
}
/* detail list for a book, person, etc.
 * used for both search results and detail pages */

dl {
    // two-part layout with left portion flexing
    display: flex;
    flex: auto;
    flex-flow: row nowrap;

    .primary {
        flex: auto;
        text-align: left;
    }

    .secondary {
        flex: none;
        text-align: right;
        padding-left: .5rem;
        @media (min-width: $breakpoint-s) { padding-left: 1rem; }
    }

    dt {
        font-size: .7rem;
        font-weight: bold;
    }

    dd {
        font-size: .8rem;
        + dt { margin-top: 1rem; }
    }

    dt, dd { line-height: 1.2rem; }
}
#page-footer {
    @include container;
    padding: 1rem 0;
    flex: none;
    background: $white;
    max-width: calc(#{$breakpoint-m} - 4rem);

    @media (min-width: $breakpoint-s) {
        padding: 4rem 0 1rem;
    }

    .nav-logos {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        line-height: 1.25;
        position: relative;
    }

    a,
    span {
        text-decoration: none;
        color: $brownish-grey;
        font-size: .6rem;
    }

    .pending::after {
        content: '*';
    }

    a {
        transition: color 0.2s;

        &:hover, &:focus {
            color: black;
        }
    }

    nav {
        margin: 0 auto;

        @media (min-width: $breakpoint-s) {
            margin: 0;
        }

        > ul {
            display: inline-block;
            vertical-align: top;
        }

        .secondary {
            display: inline-block;
            margin-left: 2rem;
        }

        li {
            padding-bottom: .2rem;
        }

        a,
        span {
            text-transform: uppercase;
        }
    }

    #sco-logo {
        flex: none;
        width: 7rem;
        display: block;
        margin: 1rem auto;

        @media (min-width: $breakpoint-s) {
            margin: 0;
            position: absolute;
            left: calc(50% - 3.5rem);
            top: 0;
        }

        img {
            width: 100%;
        }
    }

    .logos-social {
        display: flex;
        flex-direction: column;
    }

    .logos,
    .social {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .social {
        margin-top: 1rem;
        justify-content: center;

        @media (min-width: $breakpoint-s) {
            justify-content: flex-end;
        }

        img {
            width: 1rem;
            height: 1rem;
            margin-left: .75rem;
        }
    }

    #pu-logo img {
        width: 6.5rem;

        @media (min-width: $breakpoint-m) {
            width: 8rem;
        }
    }

    #cdh-logo {
        margin-left: 1rem;

        @media (min-width: $breakpoint-m) {
            margin-left: 2rem;
        }

        img {
            width: 5rem;
        }
    }

    .links {
        width: 100%;
        padding-top: 1rem;
        display: flex;
        flex-flow: row wrap;

        a,
        span {
            display: inline-block;
            flex: auto;
            width: 90%;   // one per line on mobile
            text-align: center;

            @media (min-width: $breakpoint-s) {
                width: auto;
            }

        }

        .note {
            flex: none;
            width: 100%;
            margin-bottom: 0.5rem;

            &::before {
                content: '*';
            }

            @media (min-width: $breakpoint-s) {
                flex: auto;
                width: fit-content;
                margin-bottom: 0;
            }
        }

        #a11y-assist {
            @media (min-width: $breakpoint-s) {
                text-align: left;
                white-space: nowrap;
                flex-grow: 0;
            }
        }

        #copyright {
            @media(min-width: $breakpoint-s) {
                margin-left: 2rem;
                text-align: left;   
                white-space: nowrap;             
            }
        }

        #sw-version {
            margin-bottom: 2rem;  // space between issn/version and a11y
            @media (min-width: $breakpoint-s) {
                text-align: right;
                margin-bottom: 0;
            }
        }

        .issn {
            @media (min-width: $breakpoint-s) {
                width: 85%;
                text-align: right;
            }                
        }
    }
}
label {
    position: relative;
}

select {
    position: relative;
    appearance: none;
    font-family: $sans;
    font-size: 0.7rem;
    padding: 8px 8px 5px;
    border: none;
    box-shadow: 0 2px 4px 0 rgba(106, 106, 106, 0.5);
    background: white;
    border-radius: 0;

    option {
        color: black;
    }

    &:disabled {
        + .dropdown.icon {
            opacity: 0.2;
        }
    }
}

label > select + .dropdown.icon {
    width: .75rem;
    height: .5rem;
    position: absolute;
    right: .3rem;
    top: calc(50% - .25rem);
    filter: invert(100%);
    transition: all 0.2s;
    z-index: 1;
    pointer-events: none;
}

label:target > select + .dropdown.icon {
    transform: rotate(180deg);
}

input[type=submit] {
    display: none;
}

.no-js input[type=submit] {
    display: inline;
}

.choice.hide {
    display: none;
}
/* basic header */
#page-header {
    flex: none;
    width: 100%;
    position: relative;
    text-align: center;
    padding: 0;
    background: $white;
    background-repeat: no-repeat;

    @media (min-width: $breakpoint-s) {
        padding: 1rem 0;
    }

    h1 {
        display: flex;
        flex: auto;
        justify-content: center;
        align-items: center;
        height: 2.5rem;
        font-size: 1.4rem;
        font-family: $display;

        @media (min-width: $breakpoint-s) {
            display: block;
            height: fit-content;
            font-size: 2.5rem;
            padding: 0;
        }
    }

    // hide and selectively display parts of the header
    .bookmark,
    .logotype,
    .chevron,
    .tagline {
        display: none;
    }

    .logotype {
        width: 12rem;
        margin: 0 auto;

        @media (min-width: $breakpoint-s) {
            display: block;
        }
    }

    .chevron {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 2rem;

        @media (min-width: $breakpoint-l) {
            width: 4rem;
        }
    }

    &.detail { // added space for detail pages
        h1 {
            height: inherit;
            padding-top: 3rem;
            padding-bottom: 0.5rem;
            text-transform: none;

            @media (min-width: $breakpoint-s) {
                padding: 0;
            }
        }
    }
}

/* header with banner image and 'bookmark' shape */
#page-header.banner {
    &.about {
        @include responsive-banner('/static/img/headers/SCo_banner_about');
    }

    &.analysis, &.discoveries {
        @include responsive-banner('/static/img/headers/SCo_banner_anlys');
    }

    &.sources {
        @include responsive-banner('/static/img/headers/SCo_banner_src');
    }

    padding: 0;
    margin-bottom: 1.5rem;
    height: 22.5rem;
    z-index: -2;
    background-position: bottom;
    background-size: auto 20rem;

    @media (min-width: $breakpoint-s) {
        height: 18rem;
        background-size: cover;
        background-position: center;
    }

    @media (min-width: $breakpoint-l) {
        margin-bottom: 4rem;
        height: 30rem;
        background-position: top;
    }

    h1 {
        font-size: 1.4rem;

        @media (min-width: $breakpoint-s) {
            display: block;
            height: fit-content;
            padding-top: 0.75rem;
        }

        @media (min-width: $breakpoint-l) {
            font-size: 2.5rem;
        }
    }

    .logotype {
        width: 10rem;

        @media (min-width: $breakpoint-s) {
            display: block;
        }

        @media (min-width: $breakpoint-l) {
            width: 12rem;
        }
    }

    .bookmark {
        z-index: -1;
        width: 13rem;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        margin: 0 auto;

        @media (min-width: $breakpoint-s) {
            display: block;
        }

        @media (min-width: $breakpoint-l) {
            width: 18rem;
        }
    }

    .chevron {
        display: block;
        bottom: 2.5rem;

        @media (min-width: $breakpoint-s) {
            bottom: 1rem;
        }
    }
}

/* homepage header */
#page-header.home {
    @include responsive-banner('/static/img/headers/SCo_home');

    height: 20rem;
    background-position: center;
    background-size: cover;

    @media (min-width: $breakpoint-s) {
        height: 27rem;
    }

    @media (min-width: $breakpoint-l) {
        height: 45rem;
    }

    h1 {
        display: none;
    }

    .logotype {
        display: block;
        width: 100%;
        padding-top: 4rem;

        @media (min-width: 460px) and (max-width: $breakpoint-s) {
            width: 460px;
        }

        @media (min-width: $breakpoint-s) {
            padding-top: calc(10rem - 5%);
            width: 65%;
        }

        @media (min-width: $breakpoint-l) {
            padding-top: 12rem;
            width: 60%;
        }

        @media (min-width: $breakpoint-xl) {
            width: 58rem;
        }
    }

    .tagline {
        display: block;
        margin: 0 auto;
        font-family: $serif;
        font-style: italic;
        font-size: 0.8rem;
        max-width: 10rem;
        padding-top: 1rem;
        padding-bottom: 8.5rem;

        @media (min-width: 460px) and (max-width: $breakpoint-s) {
            padding-top: 0.5rem;
        }

        @media (min-width: $breakpoint-s) {
            padding-top: 0;
            max-width: none;
        }

        @media (min-width: $breakpoint-m) {
            font-size: 1rem;
        }

        @media (min-width: $breakpoint-l) {
            font-size: 1.4rem;
            width: 60%;
        }
    }

    .chevron {
        display: block;
        bottom: .5rem;

        @media (min-width: $breakpoint-s) {
            bottom: 10rem;
        }

        @media (min-width: $breakpoint-m) {
            bottom: 7rem;
        }

        @media (min-width: $breakpoint-l) {
            bottom: 16rem;
        }

        @media (min-width: $breakpoint-xl) {
            bottom: 14rem;
        }
    }
}

/* search pages header; can stick to top on mobile */
#page-header.search {
    position: fixed;
    z-index: 1;

    @media (min-width: $breakpoint-s) {
        position: relative;
    }
}

/* error page header: h1 is moved down, lots of margin */
#page-header.error {

    .logotype {
        display: block;
        margin-top: 2rem;
        margin-bottom: 6.5rem;

        @media (min-width: $breakpoint-s) {
            margin-top: 0;
            margin-bottom: 9rem;
        }

        @media (min-width: $breakpoint-m) {
            margin-bottom: 14rem;
        }
    }

    h1 {
        text-transform: none;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 7rem;

        @media (min-width: $breakpoint-s) {
            font-size: 1.4rem;
            top: 10rem;
        }

        @media (min-width: $breakpoint-m) {
            font-size: 3rem;
        }
    }
}

#page-header.content {

    h1 {
        text-transform: none;
    }
}
img.responsive {
    display: block;
    margin: 0 auto 2rem;
    max-width: 100%;
}
/* introductory main content, e.g. on homepage */

section.intro {
    width: 100%;
    background: $white;
    font-family: $serif;
    line-height: 1.5rem;
    padding-top: 2.5rem;

    @media (min-width: $breakpoint-s) {
        padding-top: 3rem;
    }

    @media (min-width: $breakpoint-l) {
        padding-top: 3.5rem;
    }

    .inner {
        @include container;
    }

    .footnote {
        font-family: $sans;
        font-size: .8rem;
        line-height: 1.2rem;
        padding: 2rem 0;
    }
}
// normal links with green underline
main a {
    @include green-link;
    color: $black;
}

// '¶' links to linkable sections in body text
.headerlink {
    color: $green;
    text-decoration: none;
    opacity: 0;
    font-size: 1rem;
    transition: opacity 0.2s ease-in-out;
    position: relative;
    top: -0.2rem;

    &:hover, &:focus {
        text-decoration: none;
    }
}

h2:hover > .headerlink {
    opacity: 1;
}

// info links using 'link' icon
.info-link {
    text-decoration: none;
    width: 2rem;
    height: 2rem;
    background-image: url('/static/img/icons/link-circle.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.8rem 0.8rem;
    display: inline-block;

    &:hover, &:focus {
        text-decoration: none;
    }
}
main {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;

    &.white {
        background: white;
    }
}

// on search pages on mobile the header can be fixed so we need offset main content
#page-header.search ~ main {
    margin-top: 2.5rem;

    @media (min-width: $breakpoint-s) {
        margin-top: 0;
    }
}

// tabbed pages, like member or book details
main.tabbed {

    nav.tabs {
        width: 100%;
        background: $white;
    }

    nav + section {
        @include container;
        flex: auto;
        background: white;
        display: flex;
        flex-direction: column;

        // subsections
        section {
            width: 100%;
            border-bottom: 0.05rem solid $white-two;

            &:last-of-type {
                border-bottom: none;
            }
        }
    }

    section .header {
        hr {
            width: 100%;
            margin: 0;
            border: none;
            background-color: $white-two;
            height: 1px;
        }
    
        h2 {
            margin: 2rem 0 1rem;
            padding-left: 1rem;
            font-family: $sans;
            font-size: 0.8rem;
        }
    }
}

// error pages
main.error {
    img {
        margin: 0 auto;

        &.e404 {
            width: 205px;

            @media (min-width: $breakpoint-s) {
                width: 513px;
            }

            @media (min-width: $breakpoint-m) {
                width: 615px;
            }
        }

        &.e500 {
            width: 270px;

            @media (min-width: $breakpoint-s) {
                width: 675px;
            }

            @media (min-width: $breakpoint-m) {
                width: 811px;
            }
        }
    }
}
// global leaflet map styles

// zoom controls
.leaflet-control-zoom {
    a {

        // larger controls on tablet and desktop
        @media (min-width: $breakpoint-s) {
            width: 50px;
            height: 50px;
            line-height: 50px;
            font-size: 30px;
        }

        // don't add the green underline hover/focus style
        &:hover,
        &:focus {
            border-bottom: 1px solid #ccc;
        }
    }
}

// popups
.leaflet-popup {
    // square corners
    .leaflet-popup-content-wrapper {
        border-radius: 2px;
    }

    // smaller margins
    .leaflet-popup-content {
        margin: 14px 10px 6px;

        // inner text styles
        * {
            margin: 0;
            font-family: $sans;
            line-height: 1.14;
        }

        > .dates {
            font-size: .8rem;
            display: block;
            margin-bottom: 6px;
        }

        > p {
            font-size: .7rem;
            color: $brownish-grey;
        }
    }

    // no close "x" button
    .leaflet-popup-close-button {
        display: none;
    }

    // green shadow
    .leaflet-popup-content-wrapper,
    .leaflet-popup-tip {
        box-shadow: 0 0 4px 0 rgba($dark-green, 0.6);
    }
}
#main-nav {
    width: 100%;
    position: fixed;
    z-index: 99;
    pointer-events: none;

    a {
        text-decoration: none;
        color: $black;

        &:visited {
            color: $black;
        }
    }

    ul.corners {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        pointer-events: none;
    }

    li.square {
        background-color: rgba(255, 255, 255, 0.8);
        transition: background-color 0.2s;
        width: 2.5rem;
        height: 2.5rem;
        pointer-events: all;

        &:hover {
            background-color: white;
        }

        @media (min-width: $breakpoint-s) {
            width: 3rem;
            height: 3rem;
        }

        @media (min-width: $breakpoint-l) {
            width: 4rem;
            height: 4rem;
        }
    }

    .home a,
    .menu a {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    .home img,
    .menu img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    .home img {
        width: 70%;
        height: 100%;
    }

    .menu img {
        width: 50%;
        height: 50%;
    }

    #main-menu {
        z-index: -100;
        pointer-events: none;
        position: fixed;
        top: 0;
        width: 100vw;
        height: 100vh;
        opacity: 0;
        background: $white;
        transition: opacity 0.5s ease;
        padding-top: 0.5rem;

        &:target { // when menu button is clicked
            opacity: 1;
            z-index: 100;
            pointer-events: all;
        }

        li.menu { // close button
            position: absolute;
            background: transparent;
            top: 0;
            right: 0;
        }

        li:not(.menu) { // nav menu items
            text-align: center;
            padding: .5rem 0;
            border-bottom: 1px solid $white-two;
            margin-left: auto;
            margin-right: auto;
            width: calc(#{$breakpoint-xs} - 2 * #{$gutter-xs});

            @media (min-width: $breakpoint-s) {
                width: calc(#{$breakpoint-s} - 2 * #{$gutter});
                line-height: 3rem;
            }

            &:last-child {
                border-bottom: none;
            }
        }

        .pending *,
        .note {
            color: $brownish-grey;
        }

        .pending .title::after {
            content: '*';
            position: absolute;
        }

        .title,
        .subtitle {
            display: block;
        }

        .title {
            font-family: $serif;
            font-size: 1.2rem;
            text-transform: uppercase;

            @media (min-width: $breakpoint-s) {
                font-size: 2.5rem;
            }
        }

        .subtitle {
            font-size: 0.7rem;
            line-height: normal;
            padding-top: .5rem;
        }

        .note {
            display: block;
            font-size: 0.7rem;
            line-height: normal;
            text-align: left;
            margin-top: .5rem;

            &::before {
                content: '*';
            }
        }
    }
}
.preview {
    width: 100%;
    text-align: center;
    background: white;
    text-decoration: none;
    display: block;
    box-sizing: border-box;
    padding: 1.5rem $gutter-xs; // content + tile scales on mobile
    transition: 0.2s all ease-in-out;
    margin-bottom: 0.2rem;

    &:hover, &:focus {
        box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.23);
        z-index: 1;
        text-decoration: none;
    }

    // content fixed to 500px but tile scales on tablet
    @media (min-width: $breakpoint-s) {
        padding: 2rem calc((100vw - 500px) / 2);
    }

    // tile + content fixed on desktop
    @media (min-width: $breakpoint-m) {
        padding: 2rem 4rem;
        margin-bottom: 0;
    }

    hr {
        width: 2.5rem;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .meta {
        font-family: $serif;
        color: $brownish-grey;
        margin-bottom: 0.5rem;
    }

    p {
        font-family: $serif;
        text-align: left;
        margin: 0 auto;
    }

    picture {
        width: 320px;
        height: 190px;
        overflow: hidden;
        display: block;
        margin-top: 1.5rem;
        margin-left: auto;
        margin-right: auto;

        @media (max-width: calc(#{$breakpoint-xs} + #{$gutter-xs} * 2)) {
            margin-left: -$gutter-xs;
            margin-right: -$gutter-xs;
        }

        @media (min-width: $breakpoint-s) {
            height: 300px;
            width: 500px;
        }

        @media (min-width: $breakpoint-m) {
            height: 210px;
            width: 355px;
        }

        img {
            width: 100%;
        }
    }
}


/* common search result styles */

.result {
    
    // override two-part layout to allow on mobile
    dl {
        flex-wrap: wrap;
        @media (min-width: $breakpoint-s) { flex-wrap: nowrap; }
        
        .secondary {
            width: 100%;
            padding-left: 0;
            
            @media (min-width: $breakpoint-s) {
                width: initial;
                padding-left: 1rem;
            }
        }
    }
    
    // relevance bar & score display
    .relevance {
        .score {
            font-size: .5rem;
            vertical-align: middle;
        }
        
        progress { max-width: 4rem; }
    }
}
// list of sub-pages that displays on landing pages: about, sources, analysis
// name of landing page will be added as a class in template to control styling

$subpages-gutter: 4px;

.subpages {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > * {
        box-sizing: border-box;
    }

    // used only on desktop for masonry
    .grid-sizer,
    .gutter-sizer {
        display: none;
        flex: none;
    }

    // don't display publication date by default
    time {
        display: none;
    }

    // layout is managed by masonry js on desktop
    @media (min-width: $breakpoint-m) {
        display: block;
        width: $breakpoint-m;
        margin: 0 auto;

        .grid-sizer,
        .gutter-sizer {
            display: block;
        }

        .grid-sizer {
            width: calc(50% - #{$subpages-gutter} / 2);
        }

        .gutter-sizer {
            width: $subpages-gutter;
        }

        .preview {
            width: calc(50% - #{$subpages-gutter} / 2);
            margin-bottom: $subpages-gutter;
        }
    }

    // essay landing page subtype, subpages are essays
    // used for analysis
    &.essays {
        // display publication date
        time {
            display: inline;
        }
    }

    // about landing page
    &.about {
        // hide descriptions
        .preview p {
            display: none;
        }
    }
}
nav.tabs {
    display: flex;
    justify-content: center;
    align-items: center;

    .tab {
        flex: 1;
        list-style: none;
        height: 2.5rem;

        &[aria-selected] {
            border-bottom: .2rem solid $green;
            font-weight: bold;

            a, span {
                font-size: 0.7rem;

                @media (max-width: $breakpoint-s) {
                    padding: 0 .5rem;
                    box-sizing: border-box;
                }

                @media (min-width: $breakpoint-s) {
                    font-size: 0.9rem;
                }
            }
        }

        &.pending {
            color: $mid-grey;

            > *::after {
                content: '*';
            }
        }
    }

    &.subsection {
        background: #fafafa;
        box-shadow: 0 5px 4px 0 rgba(0, 0, 0, 0.19);
        z-index: 12;

        @media (min-width: $breakpoint-m) {
            margin-bottom: 2.5rem;
        }

        .tab {

            &:first-child {
                border-right: 1px solid $white-two;
            }

            a, span {
                text-transform: capitalize;
            }
        }
    }


    a, span {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        text-transform: uppercase;
        font-size: 0.5rem;

        @media (min-width: $breakpoint-s) {
            font-size: 0.9rem;
        }
    }

    a { // remove default link style
        text-decoration: none;
    }
}
span[role=tooltip] {
    width: 1.5rem;
    height: 100%;
    background-image: url('/static/img/icons/information-circle.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
    min-height: 1rem;
    display: inline-block;


    &.question {
        background-image: url('/static/img/icons/question-circle.svg');
    }

    @media (min-width: $breakpoint-s) {
        width: 0.75rem;
        background-size: 100%;
    }

    &::before { // the pointing triangle
        content: url('/static/img/icons/informationTriangle.svg');
        width: 1rem;
        height: 1rem;
        right: .25rem;
        bottom: 1rem;
        filter: drop-shadow(0 4px 4px $transparent-blue);
        z-index: 3; // above textbox

        @media (min-width: $breakpoint-s) {
            right: -0.125rem;
            bottom: .75rem;
        }
    }

    &::after { // where the text is located
        content: attr(aria-label); // automatically set based on parent aria-label
        right: -0.5rem;
        bottom: 1.75rem;
        font-size: 0.7rem;
        color: $brownish-grey;
        text-align: left;
        line-height: 1.14;
        padding: 0.4rem 0.8rem;
        background-color: white;
        z-index: 2;
        box-shadow: 0 0 4px 0 $transparent-blue;
        pointer-events: none;

        @media (min-width: $breakpoint-s) {
            bottom: 1.5rem;
        }
    }

    &::before,
    &::after {
        position: absolute;
        opacity: 0;
        transition: opacity 0.5s;
    }

    &:hover,
    &:focus { // show when hovered, focused or clicked/tapped
        &::before,
        &::after {
            opacity: 1;
        }
    }
}