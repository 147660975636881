/* member activity tables (membership and borrowing) */

$table-padding: 1rem;
$shadow: rgba(0, 0, 0, 0.2);

.activities {

    form {
        display: none;
    }

    table {
        width: 100%;
    }

    :target {
        /* override default target styles */
        border-left: none;
        margin-left: 0;
        /* add highlight color */
        background-color: $ice;
    }

    // mobile styles: table rows are expandable cards, no headers
    @media (max-width: calc(#{$breakpoint-s} - 1px)) {

        form { // show sorting controls
            display: block;
        }

        thead {
            display: none;
        }

        tr {
            padding: 1rem 0.75rem;
            border-bottom: solid 1px $light-grey;
            display: flex;
            flex-wrap: wrap;
            position: relative;

            &:first-child { border-top: solid 1px $light-grey; }
        }

        td {
            width: 100%;
            font-size: 0.7rem;
        }

        /* hide end dates for single-day events */
        .purchase .end, .reimbursement .end {
            opacity: 0;
        }

        /* add spacing around notice for no activity content */
        > p {
            padding: 1rem;
        }

    }

    // tablet and desktop styles
    @media (min-width: $breakpoint-s) {

        thead {

            tr {
                border-top: none;
            }

            th:first-child {
                text-align: left;
            }

            th:last-child {
                text-align: right;
            }
        }

        th {
            position: sticky;
            height: 3rem; // same as height of nav blocks
            top: 0;
            z-index: 10;
            background: white;
            font-size: 0.7rem;
            font-weight: bold;
            vertical-align: middle;

            // sticky table headers
            // stick a single (leftmost) header, set a shadow underneath it
            // that spans the page width
            &.stuck::before {
                content: ' ';
                z-index: -1;
                position: absolute;
                bottom: -1px;
                margin-left: -50vw;
                width: 200vw;
                height: 100%;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
                background: white;
            }
        }

        // styles adapted from tablesort
        th[role=columnheader]:not([data-sort-method=none]) {
            cursor: pointer;

            // display icon to indicate sortable and current sort status
            &::after {
                content: '';
                display: inline-block;
                margin-left: 0.35rem;
                background-image: url('/static/img/icons/inactive-table-sort.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: 200%;
                vertical-align: middle;
                width: 0.8rem;
                height: 1.35rem;
                box-shadow: 0 2px 4px 0 $shadow;
            }

            &[aria-sort=ascending]::after {
                background-image: url('/static/img/icons/selected-table-sort-down.svg');
            }

            &[aria-sort=descending]::after {
                background-image: url('/static/img/icons/selected-table-sort-up.svg');
            }
        }

        td {
            font-size: 0.8rem;
            padding: $table-padding calc(#{$table-padding} / 2);
            text-align: center;

            &:first-child {
                text-align: left;
            }

            &:last-child {
                text-align: right;
            }
        }

        tbody tr {
            border-top: solid 2px $white-two;
            border-bottom: solid 2px $white-two;
            // clear sticky table header when using anchor link to title
            scroll-margin-top: 100px;

            &:last-child {
                border-bottom: none;
            }
        }
    }

    // tablet only styles: scrollable table with fixed left column
    @media (min-width: $breakpoint-s) and (max-width: calc(#{$breakpoint-m} - 1px)) {

        overflow: auto; // scrollable

        .wrapper { // table always expands to fill largest tablet size
            width: $breakpoint-m;
        }

        th {
            padding-top: $table-padding;
        }

        td, th {
            &:first-child {
                // width: 10rem;
                padding-left: $table-padding;
            }

            &:last-child {
                padding-right: $table-padding;
            }
        }

        // td:first-child {
        //     position: absolute;
        //     background: white;
        //     margin-top: -1.8rem;
        // }
    }

    // desktop only styles: fixed-width table
    @media (min-width: $breakpoint-m) {

        @include padding-container; // fixed width

        .wrapper {
            width: 100%;
        }

        td {

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }

    }

    @media (min-width: $breakpoint-l) {

        th {
            height: 4rem;
        }
    }
}
section[aria-label=biography] {
    max-width: calc(#{$breakpoint-s} - #{$breakpoint-xs});
    margin-top: $gutter-xs;
    margin-bottom: $gutter-xs;

    @media (min-width: $breakpoint-s) {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    @media (min-width: $breakpoint-m) {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    // special display for notes
    dt.notes { @include sr-only; }

    dd.notes {
        margin-top: 2rem;
        white-space: pre-line;
    }

    .member-dates .date-range {
        display: block;
    }

}
/* members borrowing activity table */
.borrowing {

    // italicize titles and edition info
    .title a, .edition em { font-style: italic; }

    .edition {
        color: $brownish-grey;
        font-size: 0.7rem;
        line-height: 0.9rem;
    }

    // mobile styles
    @media (max-width: calc(#{$breakpoint-s} - 1px)) {

        .title { order: 1; }
        .activity { order: 2; }
        .start { order: 3; }
        .end { order: 4; }

        .pubdate {
            margin-top: 1.5rem;
            position: relative;
            order: 5; // always goes last

            &::before {
                content: 'Publication Date';
                position: absolute;
                bottom: 1rem;
                font-size: 0.6rem;
                font-weight: bold;
            }
        }

        .edition { margin-top: .5rem; }

        .activity { margin-top: .25rem; }

        td:not(.author) {
            padding-right: 50%;
        }

        .author { // removed from flow; always at right of card
            position: absolute;
            right: 1rem;
            top: 1rem;
            width: 45%;
            text-align: right;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .empty, .purchase .end {
            display: none;
        }

        .borrow {
            .start::after,
            .end::before {
                content: ' — ';
            }

            .start:not(.empty) + .end:not(.empty)::before {
                content: '';
                display: none;
            }
        }

        // uncertainty icon displayed at bottom of card
        tr.uncertain {
            padding-bottom: 1.5rem;

            &::after {
                content: url('/static/img/icons/uncertainty.svg');
                width: .75rem;
                height:  .75rem;
                position: absolute;
                bottom: .75rem;
            }
        }
    }

    // tablet and desktop styles
    @media (min-width: $breakpoint-s) {

        .start,
        .end,
        .pubdate {
            min-width: 6rem;
        }

        .activity {
            min-width: 5rem;
        }

        // uncertainty icon displayed to the right of titles
        tr.uncertain {
            .title a { position: relative; }

            .title a::after {
                z-index: 0;
                content: url('/static/img/icons/uncertainty.svg');
                width: .75rem;
                height:  .75rem;
                position: absolute;
                bottom: .25rem;
                right: -1rem;
            }
        }
    }

    // desktop-only styles
    @media (min-width: $breakpoint-m) {
        tr.uncertain .title a::after {
            right: 0;
            left: -1.5rem;
        }
    }
}
// make the sorting dropdown non-interactive since it's auto-selected
.members.sort-pages select[name=sort] {
    pointer-events: none;
    
    + .dropdown.icon {
        opacity: 0.2;
    }
}
/* member detail list (<dl>) styles */


#members-form {

    // "has card" facet
    label[for=id_has_card] {

        @media (min-width: $breakpoint-s) {
            max-width: 7rem;
            margin-right: 0.1rem;
        }

        span:first-child {
            padding-top: .1rem;
        }

        span[role=tooltip]::after {
            width: 12rem;
            left: -12rem; // keep all of tooltip text onscreen on mobile

            @media (min-width: $breakpoint-s) {
                left: -6rem; // large enough width to center it on tablet+
            }
        }
    }

    // sizing for "membership dates" filter
    #id_membership_dates {
        legend { // "membership dates" text is too long; needs to wrap
            width: 50%;
        }

        @media (min-width: $breakpoint-s) {
            max-width: 13rem;
            margin-right: 0.1rem;
        }

        @media (min-width: $breakpoint-m) {
            max-width: 15rem;
        }
    }

    // on tablet only, all facets get taller to accommodate wrapped "membership dates" text
    @media (min-width: $breakpoint-s) and (max-width: calc(#{$breakpoint-m} - 1px)) {
        .group > .inner > .range.facet,
        .group > .inner > label,
        .group > .inner > .tab {
            height: 3rem;
        }

        .group .range.facet legend {
            line-height: 1.2;
            top: 0.6rem;
        }
    }

    // on mobile only, all facets/filters have a slight break in between them
    @media (max-width: calc(#{$breakpoint-s} - 1px)) {
        .group > .inner > .range.facet,
        .group > .inner > label,
        .group > .inner > .tab,
        #id_gender {
            margin-bottom: 0.1rem;
        }
    }

    // "birth year" is a range facet but it goes inside a tab on large screens
    #id_birth_year {
        margin-bottom: 0.1rem;

        @media (min-width: $breakpoint-s) {
            border: 0;
            padding: 0;
            max-width: none;
            height: fit-content;
            margin: 0 0 1rem;

            .inputs,
            legend {
                position: relative;
                display: block;
                width: 100%;
                text-align: left;
                right: unset;
                top: unset;
            }

            legend {
                margin-bottom: 0.5rem;
            }

            .inputs {
                margin-left: 0.5rem;
            }
        }
    }

    // gender facet info link
    #gender_tip {
        position: absolute;
        top: 2.7rem;
        left: 3.6rem;

        @media (min-width: $breakpoint-s) {
            top: 3.75rem;
        }
    }

    // nationality facet info link
    #nationality-info {
        position: absolute;
        left: 4.75rem;
        top: 0;

        @media (min-width: $breakpoint-s) {
            z-index: 1;
            top: -0.5rem;
        }

    }

    // make the tabpanels display when their tabs are aria-selected
    .demographics.tab[aria-selected=true] ~ #demographics-panel,
    .books.tab[aria-selected=true] ~ #books-panel {

        @media (min-width: $breakpoint-s) {
            display: block;
            max-height: 13.5rem;
            border-color: $green;

            > .inner {
                max-height: 13.5rem;
            }
        }
    }

    .books.tab {
        margin-right: 0;
    }
}
/* member address map */

section[aria-label=map]  {
    margin-bottom: 2rem;
    z-index: 0; // strange bug...map will be on top of main menu when it's open unless this is set

    // on mobile, the map part only is full-width.
    // on all other screens, the entire content of the section has margins.
    @media (min-width: $breakpoint-s) {
        @include container;
    }

    @media (max-width: calc(#{$breakpoint-s} - 1px)) {
        .header {
            @include container;
        }
    }

    #address-map {
        width: 100%;
        height: 20.75rem;

        @media (min-width: $breakpoint-s) {
            height: 25.75rem;
        }

        @media (min-width: $breakpoint-m) {
            height: 34.35rem;
        }
    }
}
/* members membership activity table */
.membership {

    // mobile styles
    @media (max-width: calc(#{$breakpoint-s} - 1px)) {

        .start,
        .end {
            margin-bottom: .5rem;
        }

        .start {
            flex: none;
            width: auto;
            order: 2;
        }

        .end { /* large enough to always push next item onto a new line */
            width: 60%;
            order: 3;
        }

        .start:not(.empty) + .end {
            padding-left: 3px;

            &::before {
                content: ' — ';
            }
        }

        .duration,
        .amount,
        .plan {
            display: inline-block;
            width: calc(100% / 3);
            position: relative;
            margin-top: 0.75rem;

            &::before {
                position: absolute;
                bottom: 0.75rem;
                left: 0;
                width: 100%;
                font-size: 0.6rem;
                font-weight: bold;
            }
        }

        .duration {
            order: 4;

            &::before {
                content: 'Duration';
            }
        }

        .plan {
            order: 5;
            text-align: center;

            &.empty {
                display: inline-block;
                opacity: 1;
            }

            &::before {
                content: 'Plan';
            }
        }

        .amount {
            order: 6;
            text-align: right;

            &::before {
                content: 'Amount';
            }
        }
    }
}

// "plan" tooltip
#plan-tip {
    vertical-align: middle;
}

// "plan" tooltip for mobile
#plan-tip-2 {
    position: absolute;
    top: -1.25rem;
    right: calc(50% - 2.75rem);

    @media (min-width: $breakpoint-s) {
        display: none;
    }
}
/* single member search result styles */

.members.result {

    // some headings are only read via SRs since they're implied visually
    dt.birth-death,
    dt.name {
        @include sr-only;
    }

    // emphasized name display
    dd.name {
        font-size: 1rem;
        font-family: $serif;
    }

    // gentler spacing on both sides
    dd.birth-death,
    dd.member-dates {
        margin-top: .5rem;
    }


    .member-dates {
        /* use padding to constrain size without messing up layout */
        /* no more than 2 date ranges per line */
        padding-left: calc(100% - 10rem);

        .date-range {
            white-space: nowrap;

            /* separate date ranges with commas */
            &:not(:last-child):after {
              content: ", ";
            }
        }

    }

    // show the "has card" icon if present
    &.has-card {

        // on mobile, the icon is positioned relative to the "primary"
        // portion of the result
        @media (max-width: calc(#{$breakpoint-s} - 1px)) {

            .primary {
                position: relative;

                &::after {
                    content: url('/static/img/icons/member_card.svg');
                    width: 1.25rem;
                    height:  1.75rem;
                    position: absolute;
                    bottom: -2.25rem;
                }
            }
        }

        // on tablet/desktop, the icon is positioned relative to the counter
        // portion of the result
        @media (min-width: $breakpoint-s) {

            .counter {
                position: relative;

                &::before {
                    content: url('/static/img/icons/member_card.svg');
                    width: 1.25rem;
                    height:  1.75rem;
                    position: absolute;
                    left: -1.75rem;
                }
            }
        }
    }
}

/* member timeline graphs */
section[aria-label=timeline] {

    // on mobile, the graph only should full-width.
    // on all other screens, the entire content of the section has margins.
    @media (min-width: $breakpoint-s) {
        @include container;
    }

    @media (max-width: calc(#{$breakpoint-s} - 1px)) {
        .header {
            @include container;
        }
    }

    .timeline .description {
        @include sr-only;
    }

    .timeline { margin-bottom: 1rem; }
}