/* typography */

html {
    font-size: 20px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

body {
    font-family: $sans;
    line-height: 1.5;
}

h1, h2, h3, h4, h5 {
    font-family: $serif;
    line-height: normal;
}

h1 {
    font-size: 2.25rem;
    text-transform: uppercase;
}

h2 {
    font-size: 1.4rem;
}

h3, h4, h5 {
    font-size: 1.2rem;
    margin: 1rem 0;
}

p {
    margin-bottom: 1rem;
}

i, em {
    font-style: italic;
}

b, strong {
    font-weight: bold;
}

blockquote {
    margin-left: 1rem;
    margin-bottom: 1rem;

    @media (min-width: $breakpoint-s) {
        margin-left: 2rem;
    }
}

figcaption {
    font-style: italic;
    color: $brownish-grey;
}

.rich-text {
    font-family: $serif;

    // ul {
    //     padding-left: 1rem;
    //     margin-bottom: 2rem;

    //     @media (min-width: $breakpoint-s) {
    //         padding-left: 2rem;
    //     }
    // }

    // li {
    //     position: relative;
    //     margin-bottom: 0.5rem;

    //     &:last-child {
    //         margin-bottom: 0;
    //     }

    //     &::before {
    //         width: 10px;
    //         height: 8px;
    //         background-color: $black;
    //         position: absolute;
    //         content: '';
    //         top: .5rem;
    //         left: -1rem;

    //         @media (min-width: $breakpoint-s) {
    //             left: -2rem;
    //         }
    //     }
    // }
}

.citation {
    margin-top: 3rem;
    overflow-x: scroll;

    h2 {
        font-family: $sans;
        font-weight: bold;
        font-size: 1.1rem;
    }

    p {
        font-family: $serif;
        margin-top: .5rem;
        margin-left: 1rem;
    }

    .project {
        font-style: italic;
    }
}

h2.subtitle,
h2.tagline {
    text-align: center;
    padding-bottom: 1.5rem;

    @media (min-width: $breakpoint-s) {
        padding-bottom: 2rem;
    }

    @media (min-width: $breakpoint-l) {
        padding-bottom: 2.5rem;
    }
}

h2.tagline {
    font-style: italic;

    > hr {
        width: 2.5rem;
        margin-top: 1.5rem;
    }
}

sup, sub {
    font-size: small;
    line-height: 0;
}

sup {
    vertical-align: super;
}
sub {
    vertical-align: sub;
}

code {
    font-family: monospace;
}