/* book detail page - bibliography section styles */

section[aria-label=bibliography] {
    max-width: calc(#{$breakpoint-s} - #{$breakpoint-xs});
    margin-top: $gutter-xs;
    margin-bottom: $gutter-xs;

    @media (min-width: $breakpoint-s) {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    @media (min-width: $breakpoint-m) {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    // some headings are only shown to SRs
    .notes dt,
    dt.read-link {
        @include sr-only;
    }

    /* uncertainty icon is displayed above the notes on mobile; to the left on
    all other screens */
    .notes {
        margin: 2rem 0;
        
        dd { white-space: pre-line; }

        &.uncertain {
            position: relative;
            margin: 2.75rem 0 2rem;

            &::before {
                content: url('/static/img/icons/uncertainty.svg');
                width: .75rem;
                height:  .75rem;
                position: absolute;
                top: -1.5rem;
            }
    
            @media (min-width: $breakpoint-s) {
                margin: 2rem 0;

                &::before {
                    top: -.1rem;
                    left: -1rem;
                }
            }
        }
    }

    .read-link a {
        display: block;
        font-size: 0.8rem;
        line-height: 1.2rem;
        margin-top: 1rem;
    }

    .volumes-issues {
        margin-top: 2rem;
        margin-bottom: 2.5rem;

        h2 {
            font-family: $sans;
            text-transform: capitalize;
            line-height: .7rem;
            font-size: .7rem;
            font-weight: bold;
        }

        li {
            margin-top: .35rem;
            font-size: .8rem;
            line-height: 1.2rem;
        }

        em {
            font-style: italic;
        }
    }
}
/* book circulation activity table */

.circulation {

    // italicize titles in edition info
    .edition em { font-style: italic; }

    .member a { display: block; }

    // mobile styles
    @media (max-width: calc(#{$breakpoint-s} - 1px)) {

        .member {
            order: 1;
        }
        .activity {
            order: 2;
        }
        .start {
            order: 3;
        }
        .end {
            order: 4;
        }

        td:not(.edition) {
            padding-right: 50%;
        }

        .edition { // removed from flow; always at right of card
            position: absolute;
            right: 1rem;
            top: 1rem;
            width: 50%;
            text-align: right;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .empty, .purchase .end {
            display: none;
        }

        .borrow {
            .start::after,
            .end::before {
                content: ' — ';
            }

            .start:not(.empty) + .end:not(.empty)::before {
                content: '';
                display: none;
            }
        }
    }

}
/* book detail list (<dl>) styles */



dl.book {
    // format heading is only shown to SRs
    dt.format { @include sr-only; }

    // small format "button" display
    dd.format {
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        font-size: 0.8rem;
        padding: .4rem .5rem .25rem;
        border: 1px solid $light-grey;
        line-height: .75rem;
        margin-top: 1.25rem;
    }
}

section[aria-label='bibliography'] {
    /* only use block date display on detail page */
    .circ {
        .date-range {
            display: block;
        }
    }
}


#books-form {

    @media (min-width: $breakpoint-s) {
        #id_circulation_dates {
            width: 50%;
        }

    }

}
/* single book search result styles */

.books.result {

    // some headings are only read via SRs since they're implied visually
    dt.title,
    dt.author,
    dt.pubdate {
        @include sr-only;
    }

    // empasized title display
    dd.title {
        font-size: 1rem;
        font-family: $serif;
    }

    // add spacing on left side
    dd.author {
        margin-top: .5rem;
        + dd.author { margin-top: 0; }
    }
    dd.pubdate { margin-top: .5rem; }


    @media (min-width: $breakpoint-s) {
        .secondary {
            /* constrain width for event dates */
            max-width: 45%;
        }
    }

    .circ {
        text-align: right;

        .date-range {
            white-space: nowrap;

            /* separate date ranges with commas */
            &:not(:last-child):after {
              content: ", ";
            }
        }

    }

    @media (max-width: calc(#{$breakpoint-s} - 1px)) {
        .primary {
            position: relative;
        }

        /* display below the primary div on mobile to align with circulation */
        dd.format {
            position: absolute;
            bottom: -1.9rem;
        }
    }

    // show the uncertainty icon if present
    &.uncertain {

        // on mobile, the icon is positioned relative to the "primary"
        // portion of the result
        @media (max-width: calc(#{$breakpoint-s} - 1px)) {

            .primary {
                position: relative;

                &::after {
                    content: url('/static/img/icons/uncertainty.svg');
                    width: .75rem;
                    height:  .75rem;
                    position: absolute;
                    bottom: -1rem;
                }
            }
        }

        // on tablet/desktop, the icon is positioned relative to the counter
        // portion of the result
        @media (min-width: $breakpoint-s) {

            .counter {
                position: relative;

                &::before {
                    content: url('/static/img/icons/uncertainty.svg');
                    width: .75rem;
                    height:  .75rem;
                    position: absolute;
                    left: -1.25rem;
                }
            }
        }
    }
}
