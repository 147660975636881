// active filters display
.active-filters {
    margin-top: 1rem;
    font-size: .8rem;
    width: 100%;
    @media (min-width: $breakpoint-s) { margin-top: 3rem; }

    &.hidden {
       display: none;
    }

    > .inner {
        @include flex-gap(.3rem);
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        align-items: center;
        @media (min-width: $breakpoint-s) { justify-content: center; }
    }

    .legend, a {
        flex: none;
    }

    .legend {
        width: 100%;
        text-align: left;
        // margin: 0 0 .5rem 0;

        @media (min-width: $breakpoint-s) {
            width: initial;
            padding-right: .6rem;
            // margin: 0;
        }
    }

    a:not(.clear-all) {
        padding: .2rem .4rem;
        background: white;
        text-decoration: none;
        box-sizing: border-box;
        &::after { content: ' ×'; }
        &:focus {
            border: .1rem solid $green;
        }
    }

    .clear-all {
        width: 100%;
        text-align: right;
        // margin-top: .5rem;
        color: $dark-green;

        @media (min-width: $breakpoint-s) {
            width: initial;
            padding-left: .6rem;
            // margin-top: 0;
        }
    }
}

.sort-pages {
    position: sticky;
    top: 2.5rem;
    background: white;
    width: 100%;
    z-index: 1;
    transition: box-shadow 0.5s;

    @include loader-bottom;

    @media (min-width: $breakpoint-s) {
        top: 0;
    }

    &.stuck {
        box-shadow: 0 2px 4px 0 $transparent-black;
    }

    .inner,
    .outer {
        display: flex;
        align-items: center;
        height: 2.5rem;

        @media (min-width: $breakpoint-s) {
            height: 3rem;
            margin: 0 3rem;
        }

        @media (min-width: $breakpoint-l) {
            height: 4rem;
            margin: 0 4rem;
        }
    }

    .inner {
        justify-content: space-around;
        margin: 0 $gutter-xs;

        @media (min-width: $breakpoint-s) {
            width: $result-width;
            margin: 0 auto;
        }

        @media (min-width: $breakpoint-m) {
            justify-content: space-between;
        }
    }

    .outer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 100%;
        pointer-events: none;

        @media (min-width: $breakpoint-s) {
            width: calc(100vw - 6rem);
        }

        @media (min-width: $breakpoint-m) {
            width: calc(#{$breakpoint-m} - 8rem);
        }

        @media (min-width: calc(#{$breakpoint-m} - 2* #{$gutter} + 8rem)) {
            width: calc(#{$breakpoint-m} - 2 * #{$gutter});
        }

        @media (min-width: $breakpoint-l) {
            @include flex-container;
        }

        nav {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
        }

        a {
            pointer-events: all;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            text-decoration: none;
            font-size: 0.7rem;
            width: $gutter-xs;

            @media(min-width: $breakpoint-s) {
                width: fit-content;
            }

            span {
                display: none;

                @media (min-width: $breakpoint-s) {
                    display: block;
                }
            }

            &[role=button] {
                cursor: pointer;
            }

            &[aria-hidden] {
                pointer-events: none;
                cursor: default;
                color: #cccccc;

                .icon {
                    /* use opacity to make black image match #ccccc */
                    opacity: 0.2;
                }

            }
        }

        .icon {
            vertical-align: middle;
            filter: invert(100%);
            height: 0.7rem;

            &.previous {
                transform: rotate(90deg);
            }

            &.next {
                transform: rotate(-90deg);
            }
        }
    }

    label {
        font-size: 0.7rem;
        position: relative;

        span {
            display: none;

            @media (min-width: $breakpoint-m) {
                margin-right: 0.5rem;
                display: inline;
            }
        }

        &::before {
            position: absolute;
            display: block;
            content: '';
            top: 2px; // will overlap chrome's outline without top/right/height
            right: 2px;
            height: 85%;
            width: 6rem;
            z-index: 1;
            background: linear-gradient(to right, $transparent 60%, white 75%);
            pointer-events: none;
        }

        .icon {
            z-index: 2;
        }
    }

    button[type=submit] {
        display: none;
        margin: 0 .25rem;
        appearance: none;

        .no-js & {
            display: block;
        }
    }

    select {
        width: 6.5rem;
        font-size: 0.6rem;
        cursor: pointer;

        .no-js & {
            width: 6rem;
        }

        @media (min-width: $breakpoint-s) {
            font-size: 0.7rem;
            width: 7rem;
        }
    }
}

/* SORT BY and DISPLAYING labels that disappear when
scrolled on mobile and tablet */
.upper-labels {
    flex: none;
    height: 2rem;
    background: white;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    margin: 0 $gutter-xs;

    @media (min-width: $breakpoint-s) {
        width: $result-width;
        margin: 0 auto;
    }

    @media (min-width: $breakpoint-m) {
        display: none;
    }

    label {
        display: inline-block;
        font-size: 0.7rem;
        width: 6.5rem;

        @media (min-width: $breakpoint-s) {
            width: 7rem;
        }
    }
}
.search-form fieldset.facet {
    background: white;
    display: block;
    width: 100%;
    padding: 0.7rem;
    position: relative;
    border: .1rem solid $transparent;
    box-sizing: border-box;

    @media (min-width: $breakpoint-s) {
        padding: 0;
    }

    > legend {
        position: absolute;
        top: 0.75rem;
        left: 0.7rem;
        padding: 0;
        display: block;

        @media (min-width: $breakpoint-s) {
            top: 0;
            left: 0;
        }
    }

    .choices {
        height: 100%;
        padding-top: 1.5rem;
        list-style: none;
    }

    .choice:hover,
    .choice:focus-within {
        background: #f1f1f1;
    }

    legend,
    label {
        font-weight: normal;
        font-size: 0.8rem;
        display: block;
        text-align: left;
        margin: 0;
        border: 0;
        padding: 0 0.5rem;
    }

    label {
        color: $brownish-grey;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: padding 0.25s ease;

        &::before {
            content: '';
            opacity: 0;
            position: absolute;
            width: .75rem;
            height: .75rem;
            left: .5rem;
            top: calc(50% - (.75rem / 2));
            background-image: url("/static/img/icons/filter_clicked_state.svg");
            background-repeat: no-repeat;
            background-position: center;
            pointer-events: none;
            transition: opacity 0.25s ease;
        }

        .count {
            font-size: 0.6rem;
        }
    }

    input {
        // borrow .sr-only styles to hide it
        @include sr-only;

        &:checked + label {
            padding-left: 1.5rem;

            &::before {
                opacity: 1;
            }
        }
    }

    &.error {
        border-color: red;
    }

    /**
     * CHOICE FACET - used for facets where all options are always shown, e.g.
     * 'gender' on members search and 'format' on books search
     */
    &.choice {
        @media (max-width: calc(#{$breakpoint-s} - 1px)) { // special styles only apply on mobile
            legend {
                padding: 0;
            }

            .choices {
                display: flex;
            }

            .choice {
                flex: auto;
                border: 1px solid $light-grey;
                padding-top: 0.1rem;
                border-left: 0;

                &:first-child {
                    border-left: 1px solid $light-grey;
                }
            }

            label {
                display: flex;
                flex-direction: column;
                padding: 0;
                color: $black;
                font-size: 0.7rem;

                &::before {
                    top: initial;
                    left: 0.2rem;
                    bottom: 0.2rem;
                    width: 0.5rem;
                    height: 0.5rem;
                }

                .count {
                    font-size: 0.5rem;
                    width: 100%;
                    text-align: right;
                    padding-right: 0.5rem;
                }
            }

            input:checked + label {
                padding: 0;
                background-color: $ice;
            }
        }
    }

    /**
     * TEXT FACET - used for facets where there can be many options, and those
     * with a count of zero will be hidden, e.g. 'nationality' on members
     */
    &.text {
        display: none;
        max-height: 0;
        overflow-y: scroll;
        transition: max-height 0.5s ease-in-out;
        border: none;
        padding: 0;

        legend {
            display: none;
        }

        .choices {
            padding: 0.5rem;
        }

        @media (min-width: $breakpoint-s) {
            display: block;
            max-height: none;
            padding: 0;
            overflow-y: auto;
            border: initial;

            legend {
                display: block;
            }

            .choices {
                padding: 1.5rem 0;
            }
        }
    }

    /**
     * RANGE FACET - two numeric inputs representing a range of values
     */

    &.range {
        padding: 0 .7rem;
        height: 2.2rem;
        margin-top: 0;

        @media (min-width: $breakpoint-s) {
            padding: 0 .5rem;
        }

        legend,
        .inputs {
            position: absolute;
        }

        legend {
            top: .55rem;
            left: .7rem;
            padding: 0;

            @media (min-width: $breakpoint-s) {
                left: 0.5rem;
            }
        }

        .inputs {
            top: calc(50% - 0.75rem);
            right: .7rem;

            @media (min-width: $breakpoint-s) {
                right: 0.5rem;
            }
        }

        .separator {
            display: inline-block;
            font-size: 0.7rem;
        }

        input {
            position: relative;
            display: inline-block;
            width: 2.4rem;
            height: 1.4rem;
            clip: auto;
        }
    }
}

// used to show/hide text facets
.search-form .expander {
    @include expandable;

    display: flex;
    background: white;
    border: .1rem solid $transparent;
    margin-bottom: .1rem;
    box-sizing: border-box;
    height: 2.2rem;
    justify-content: left;
    align-items: center;
    padding-left: .75rem;
    outline: none;
    cursor: pointer;

    &:focus {
        border-color: $green;
    }

    @media (min-width: $breakpoint-s) {
        display: none;
    }

    &[aria-expanded=true]::after {
        transform: rotate(180deg);
    }

    &[aria-expanded=true] ~ .facet {
        display: block;
        max-height: 11rem;
    }
}

@media (max-width: calc(#{$breakpoint-s} - 1px)) {
    input:checked + label {
        /* can't figure out correct place to override white bg for card filter */
        background-color: $ice !important;
    }
}
/* search form styles */

.search-form {
    @include padding-container;
    padding-top: 1rem;
    background: $white;

    form {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;
    }

    input[name=query] {
        flex: auto;
        padding: .2rem 0;
        font-size: 0.8rem;
        background-color: transparent;
        border-width: 0 0 2px;
        border-color: $black;
        border-bottom-style: dotted;
        outline: none;
        transition: border 0.5s;

        &::placeholder {
          color: $nearly-black;
          opacity: 1; /* Firefox */
        }

        + label {
            display: inline-block;
            width: 1rem;
            height: 1rem;
            background-image: url('/static/img/icons/search.svg');
            background-position: center center;
            background-repeat: no-repeat;
        }

        &:focus {
            border-bottom-style: solid;
        }
    }

    input[type=number] {
        appearance: textfield;
        font-size: 0.8rem;
        color: $black;
        text-align: center;
        border: 1px solid $light-grey;

        // remove spinners in webkit browsers due to bug: when they are active,
        // clicking the down arrow will eventually cause input value to "wrap"
        // from min back up to max, rather than staying at min
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
            display: none;
        }

        @media (min-width: $breakpoint-s) {
            border-color: $black;
            border-style: dotted;
            border-width: 0;
            border-bottom-width: .1rem;

            &:focus {
                border-bottom-style: solid;
            }
        }
    }

    // fade animation for when count changes
    span.count {
        opacity: 1;
        transition: opacity 0.25s ease-in-out;

        &[aria-busy] {
            opacity: 0;
        }
    }

    fieldset.group {
        width: 100%;
        font-size: 0.8rem;

        > legend {
            @media (max-width: calc(#{$breakpoint-s} - 1px)) {
                @include sr-only;
            }

            width: 100%;
            text-align: left;
            font-weight: bold;
            padding: 1.75rem 0 0.5rem;
        }

        // chrome doesn't allow fieldsets to use flex, so we have to do this
        // https://bugs.chromium.org/p/chromium/issues/detail?id=262679
        > .inner {
            margin-top: 3.5rem;
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-items: center;

            @media (min-width: $breakpoint-s) {
                margin-top: 0;
            }
        }

        // 2px vert spacing on mobile, 2px horizontal spacing on tablet & above
        > .inner > label,
        > .inner > .facet,
        > .inner > .tab {
            // margin-bottom: 0.1rem;

            @media (min-width: $breakpoint-s) {
                margin-right: 0.1rem;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }

        /* boolean facet */
        > .inner > label {
            height: 2.2rem;
            background: white;
            flex: auto;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            padding: 0 .7rem;
            align-items: center;
            border: 0.1rem solid $transparent;
            box-sizing: border-box;

            @media (min-width: $breakpoint-s) {
                padding: 0 .5rem;
            }

            &:last-child {
                margin-right: 0;
            }

            img.icon {
                max-height: 1.5rem;
                margin-left: 0.5rem;
            }

            .count {
                font-size: 0.6rem;
            }

            span[role=tooltip] {
                position: absolute;
                height: 100%;
                right: .2rem;
                top: 0;

                @media (min-width: $breakpoint-s) {
                    right: .5rem;
                }
            }
        }

        > .inner > input:focus + label {
            border-color: $green;
        }

        > .inner > input[type=checkbox] + label {
            cursor: pointer;
        }

        // uses after so it can stack with tooltip info icon
        > .inner > input[type=checkbox] + label::after {
            content: '';
            opacity: 0;
            position: absolute;
            width: .75rem;
            height: .75rem;
            right: .5rem;
            top: calc(50% - (.75rem / 2));
            background-image: url("/static/img/icons/filter_clicked_state.svg");
            background-repeat: no-repeat;
            background-position: center;
            pointer-events: none;
            transition: opacity .25s ease;
        }

        > .inner > input:checked + label::after {
            opacity: 1;
        }

        // full-width input styles for mobile only
        @media (max-width: calc(#{$breakpoint-s} - 1px)) {
            > .inner {
                width: 100vw;
            }

            > legend {
                margin-left: $gutter-xs;
                margin-right: $gutter-xs;
            }
        }
    }

    // errors output
    div[role=alert].errors {
        width: 100%;
        text-align: left;
        padding-top: 1.75rem;
        font-size: 0.8rem;

        span { // "Errors:" text
            font-weight: bold;
        }

        > ul { // master list of errors
            list-style: none;
            display: block;
            background: white;
            padding: 0.8rem;
            position: relative;
        }

        li {
            padding-left: 1rem;
        }

        // error icons
        li::before {
            content: url('/static/img/icons/error-circle.svg');
            width: 0.75rem;
            height: 0.75rem;
            position: absolute;
            left: 0.8rem;
            padding-top: 0.05rem;
        }
    }

    // total results & "loading..." display
    .total-results,
    .loader {
        width: 100%;
        font-family: $serif;
        font-style: italic;
        font-size: 0.8rem;
        margin: 2rem 0 1rem;
        transition: opacity 0.5s;
    }

    .loader {
        opacity: 0;
        position: absolute;
        bottom: 0;
        flex: none;
    }

    &[aria-busy] {
        .total-results {
            opacity: 0;
        }
        .loader {
            opacity: 1;
        }
    }
}
output.results {
    transition: opacity 0.5s;

    ol {
        @include flex-container;
    }

    &[aria-busy] {
        opacity: 0.25;
    }
}
/* search result styles */

// single search result display
.result {
    counter-increment: results;
    position: relative;
    border-top: 1px solid $white-two;

    a { text-decoration: none; }

    .container {
        position: relative;
        display: flex;
        align-items: baseline;
        width: 100%;
        max-width: $result-width;
        height: 100%;
        padding-top: 1rem;
        padding-bottom: 2rem;
        border: none;
        margin: 0 auto;

        @media (min-width: $breakpoint-s) {
            flex-flow: row nowrap;
        }
    }

    // show the result counter/icons in the details
    .counter {
        display: block;

        // used to position "has card" icon when on tablet & larger
        @media (min-width: $breakpoint-s) {
            position: relative;
        }

        &::after {
            content: counter(results);
            font-size: 0.8rem;
            margin-right: 0.5rem;
        }
    }

    // links to "see more" only shown on hover
    a:hover .see-more {
        visibility: visible;
    }

    .see-more {
        position: absolute;
        display: block;
        right: 0;
        bottom: 0.5rem;
        color: $dark-green;
        font-size: 0.7rem;
        font-weight: bold;
        margin-right: 0.7rem;

        @media (min-width: $breakpoint-s) {
            right: calc((100% - #{$result-width}) / 2);
        }

        @media (pointer: fine) { // hide "see more" on non-touch devices
            visibility: hidden;
        }

        &::after { // green triangle
            content: '';
            background-image: url('/static/img/icons/more_triangle.svg');
            background-repeat: no-repeat;
            position: absolute;
            height: .7rem;
            width: .7rem;
            top: 0.15rem;
            right: -1rem;
        }

        .triangle.icon {
            height: .75rem;
            vertical-align: middle;
        }

        a:hover & {
            visibility: visible;
        }
    }
}

// empty results display
.empty-results {
    @include padding-container;
    padding-top: 3.5rem;
    text-align: center;
    font-family: $serif;
    min-height: 8rem;

    img {
        width: 50%;
    }
}
/* search form tabs/panels */

.search-form {
    .tab {
        flex: auto;
        background: white;
        position: relative;
        border: .1rem solid $transparent;
        box-sizing: border-box;
        height: 2.2rem;
        margin-top: 0;
        display: none;
        justify-content: left;
        padding-top: .2rem;
        padding-left: 0.5rem;
        align-items: center;
        outline: none;
        cursor: pointer;

        &:last-of-type {
            margin-right: 0;
        }

        &::after {
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            right: 0.5rem;
            top: calc(50% - 4px);
            border-style: solid;
            border-width: 8px 7px 0;
            border-color: black $transparent $transparent $transparent;
            // transition: all 0.3s ease-in-out;
        }

        &:focus {
            border-color: $green;
        }

        &[aria-selected=true] {
            border-bottom: none;
        }

        &[aria-selected=true]::after {
            transform: rotate(180deg);
        }

        &[aria-disabled] {
            color: $mid-grey;
            cursor: auto;

            &::after {
                border-color: $mid-grey $transparent $transparent $transparent;
            }
        }

        @media (min-width: $breakpoint-s) {
            display: flex;
        }
    }

    .tabpanel {
        width: 100%;
        flex: none;

        > label {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 2.5rem;
            width: 100%;
        }

        .column {
            position: relative;
        }

        @media (min-width: $breakpoint-s) {
            border-top: 2px solid $white;
            background: white;
            display: none;
            width: 100%;
            overflow: hidden;
            transition: all 0.5s ease-in-out;

            > label {
                display: none;
            }

            > .inner {
                width: 100%;
                display: flex;
                flex-direction: row nowrap;
                padding-top: 1rem;

                > .column {
                    flex: 1;
                    padding: 0 0.5rem 0.5rem;
                    box-sizing: border-box;
                    border-right: 1px solid $white-two;
                    overflow-y: scroll;
                    scrollbar-color: $white-two white;

                    &:last-child {
                        border-right: 0;
                    }
                }
            }

            .no-js & {
                max-height: 13.5rem;
            }
        }
    }
}